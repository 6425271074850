<template>
    <div class="box" v-if="env=='wx'">
        <div class="form_box" v-if="!bindSuccess">
            <div class="logo_box">
                <img src="@/assets/image/logonew.png" alt="" class="logo">
            </div>
            <van-form ref="form" validate-first >
                <van-field name="mobile" label="手机号码" v-model.trim="userInfo.mobile" :error-message="hasPhone?errorMessage:''" type="number" class="mb-5"  placeholder="请输入绑定的手机号码" :rules="rules.mobile" @input="changePhone"/>
                <van-field name="imgCode" label="图形验证码" v-model="userInfo.imgCode" :rules="rules.imgCode" placeholder="请输入图形验证码">
					<template #extra>
						<van-image :src="txmImg" width="80px" @click="changeImg" fit="scale-down">
							<template #loading>
								<van-loading type="spinner" size="20" />
							</template>
						</van-image>
					</template>
				</van-field>
                <van-field name="msgCode" label=短信验证码 type="number" v-model="userInfo.msgCode" :rules="rules.msgCode" placeholder="请输入短信验证码">
                    <template #button>
                        <van-button size="small" type="info" @click.stop="sendMsg"   :disabled="waitMsg" :loading="msgLoading">
                            <span v-show="!waitMsg">获取验证码</span>
                            <van-count-down v-show="waitMsg" ref="countDown" class="time" :time="60000" @finish="finish">
                                <template #default="timeData">
                                    <span class="send_msg_btn">{{ timeData.seconds }}秒</span>
                                </template>
                            </van-count-down>
                        </van-button>
                    </template>
                </van-field>
                 <div class="btn">
				    <van-button type="info" block native-type="submit" :loading="bindLoading" loading-text="绑定中..." @click="onSubmit">绑定</van-button>
			    </div>
		    </van-form>
            <div class="tip">提示：绑定的手机号要与耀保网账号的手机号一致，用于接收订单信息</div>
        </div>
        <!-- 绑定成功 -->
        <div v-else class="bind_success_page">
            <van-loading v-if="!bindType" vertical color="#2594ef" text-color="#2594ef" class="loading">加载中..</van-loading>
            <div v-if="bindType=='has'">
                <div class="info_box">
                    <div class="info_title">已绑定信息</div>
                    <div class="info_text">账号名：{{ bindInfo.userName }}</div>
                    <div class="info_text">手机号：{{ bindInfo.mobile }}</div>
                </div>
                <div class="info_tip">绑定的手机号用于接收承保信息和退保信息，更多功能敬请期待</div>
		        <van-button type="danger" block native-type="submit" :loading="bindLoading" loading-text="解绑中..." @click="goDelBind">解绑</van-button>
            </div>
            <div v-if="bindType=='un'">
                <div class="icon_un"></div>
                <div class="text">未绑定</div>
                <div class="info_tip mt_30 text_center">绑定的手机号用于接收承保信息和退保信息，更多功能敬请期待</div>
		        <van-button type="info"  block native-type="submit"  @click="goBind">去绑定</van-button>
            </div>
        </div>
    </div>
    <div v-else class="weChat_warning"> <h4>请在微信中打开</h4></div>
</template>

<script>
import { Field, Form,Button,Icon,CountDown,Image as VanImage,Loading,Toast,Dialog} from 'vant';
import regular from '@/assets/js/regular';
import {http_checkInsUserOpenIdMobile,http_getOpenId,http_checkInsUserOpenIdByOpenId,http_deleteInsUserOpenIdByOpenId,http_smsCode,http_getInsUserOpenIdAppId,http_addInsUserOpenId} from "@/request/common.js"
export default {
    components: {
        [Form.name]: Form,
        [Button.name]: Button,
		[Field.name]: Field,
		[Icon.name]: Icon,
        [CountDown.name]: CountDown,
		[VanImage.name]: VanImage,
		[Loading.name]: Loading,
		[Toast.name]: Toast,
		[Dialog.name]: Dialog,


    },
    data() {
        return {
            userInfo: {
                mobile:'',
                msgCode:'',
                imgCode:'',
            },
            key:'',
            txmImg:'',
            waitMsg:false,
            msgLoading:false,
            hasPhone:false,
            wxAppId:'',
            openId:'',
            errorMessage:'',
            rules: {
                mobile: [
                    { required: true, message: '',trigger: 'onBlur' },
                    { pattern: regular.phone, message: '请输入正确的手机号码',trigger: 'onBlur' },
                ],
                msgCode: [{ required: true,message: '',trigger: 'onChange' }],
                imgCode: [{ required: true,message: '',trigger: 'onChange' }],
            },
            bindLoading:false,
            bindSuccess:true,
            bindType:'',
            bindInfo:{}
        }
    },
    watch: {
        $route:{
            handler:function(val){
				this.env = this.$base.getEnv();
                if(this.env!=='wx'){
                    return
                }
                let params = val.query;
               
                if(Object.hasOwnProperty.call(params, 'code')){
                    this.getOpenId(params.code).then((openid) => {
                        //判断是否绑定
                        http_checkInsUserOpenIdByOpenId(openid).then(res=>{
                            if(res.success){
                                this.bindSuccess=true
                                this.bindType="un"
                            }else{
                                this.bindInfo=res.data
                                this.bindSuccess=true
                                this.bindType="has"
                            }
                        })
                    });
                }else{
                     this.getWxParams().then((appId) => {
                        this.wxAppId=appId
                        this.getWxCode(appId);
                    });
                }
            },
            immediate:true
        }
    },
    created () {
       
    },
    methods: {
        //解绑
        goDelBind(){
            Dialog.confirm({
                title: '提示',
                message: '确定要解绑吗？',
            }).then(()=>{
                this.bindLoading=true
                http_deleteInsUserOpenIdByOpenId(this.openId).then(res=>{
                    Toast.success('解绑成功')
                    this.bindType="un"
                    this.bindSuccess=true
                }).finally(res=>{
                    this.bindLoading=false
                })
            })
          

        },
        //去绑定
        goBind(){
            this.waitMsg=false
            this.changeImg();
            this.bindSuccess=false
        },

        //改变图片验证码
		changeImg() {
			let time = new Date().getTime();
			this.key = time;
			this.txmImg = '/insurance/api/common/verifyCode/' + time;
		},

        //号码发生改变
        async changePhone(value){
            this.hasPhone=false
            this.errorMessage=""
            const pattern=regular.phone;
            if(pattern.test(value)){
                const res= await http_checkInsUserOpenIdMobile(value)
                if(!res.success){
                    this.hasPhone=true
                    this.errorMessage=res.message
                }
            }
        },
        finish() {
            this.waitMsg = false;
        },
         sendMsg() {
            if(this.hasPhone){
                return 
            }
		    this.$refs.form.validate(['mobile','imgCode']).then((value) => {
				this.msgLoading = true;
				http_smsCode({code:this.userInfo.imgCode,key:this.key,mobile:this.userInfo.mobile,type:'WXBIND'})
					.then(res => {
						if (this.$refs.countDown) {
							this.$refs.countDown.reset();
						}
						this.waitMsg = true;
					})
					.finally(() => {
						this.msgLoading = false;
					});
			});
          
		},
        //1.获取公众号参数appid
        async getWxParams() {
            return new Promise(resolve => {
				http_getInsUserOpenIdAppId().then(res => {
					resolve(res);
				});
			});
		},
        // 2. 拿着appId，去公众号换取code
		getWxCode(appId) {
			let redictUrl = encodeURIComponent(window.location.href);
			let url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appId}&redirect_uri=${redictUrl}&response_type=code&&state=${new Date().getTime()}&scope=snsapi_base#wechat_redirect`;
			window.location.replace(url);
		},
        // 3. 拿着code，通过接口请求openId
		getOpenId(code) {
			return new Promise(resolve => {
				http_getOpenId(code).then(res => {
					this.openId = res.openid;
					resolve(res.openid);
				});
			});
		},
     
        onSubmit(){
          
            if(this.hasPhone){
                return
            }
            this.$refs.form.validate().then(res=>{
                this.bindLoading=true
                http_addInsUserOpenId({
                    openId:this.openId,
                    mobile:this.userInfo.mobile,
                    verifyCode:this.userInfo.msgCode
                }).then(_=>{
                    Toast.success('绑定成功')
                    http_checkInsUserOpenIdByOpenId(this.openId).then(res=>{
                        if(!res.success){
                            this.userInfo={}
                            this.bindInfo=res.data
                            this.bindSuccess=true
                            this.bindType="has"
                        }
                    })
                }).finally(()=>{
                    this.bindLoading=false

                })
            })
           
        }
    },
}
</script>

<style lang="scss" scoped>
.box{
    height: 100vh;
    box-sizing: border-box;
    overflow: hidden;
    padding-top: 16vh;
}
.logo_box{
    display: flex;
    justify-content: center;
    margin: 0.2rem 0 0.8rem;
}
.logo{
    width: 50vw;
}
.title{
    font-size:0.4rem ;
    font-weight: bold;
    text-align: center;
    padding: 0.2rem 0 0.3rem;
    color: $b_color_main;
}
.form_box{
    padding: 0.3rem 0 0.3rem;
    background-color: #fff;
    border-radius: 0.2rem;
    margin: 0 0.2rem;
    
}
.tip{
    font-size: 0.2rem;
    padding:0.2rem 0.3rem 0;
    color: $danger;

}
.btn{
    padding:0.4rem 0.2rem 0.2rem;
}
.mb-5{
    margin-bottom: 0.2rem;
}

.bind_success_page {
    padding: 0.2rem;
    .loading{
        margin-top: 3rem;
    }
    .info_box{
        font-size: 0.3rem;
        margin-bottom: 0.4rem;
        border-radius: 0.2rem;
        padding: 0.2rem 0.2rem;
	    box-shadow: 0rem 0.04rem 0.16rem 0rem rgba(0,0,0,0.1);

        .info_title{
            font-weight: bold;
            color: $b_color_main;
            text-align: center;
            margin-bottom: 0.2rem;
        }
        .info_text{
            margin-bottom:0.3rem;
        }
        
      

    }
    .info_tip{
        font-size: 0.24rem;
        color: $b_font_color_info1;
        margin-bottom: 0.6rem;

    }
    .text_center{
        text-align: center;
    }
  
	.icon {
		width: 1.45rem;
		height: 1.45rem;
		margin: 1.6rem auto 0.5rem;
		background: url(../assets/icon/success.png);
		background-repeat: no-repeat;
		background-size: 100%;
		background-position: center;
	}
    .icon_un{
        width: 1.45rem;
		height: 1.45rem;
		margin: 1.6rem auto 0.5rem;
		background: url(../assets/icon/unBind.png);
		background-repeat: no-repeat;
		background-size: 100%;
		background-position: center;
    }
	.text {
		font-size: 0.3rem;
		color: #052433;
		line-height: 0.3rem;
		font-weight: bold;
		text-align: center;
	}
    .name_box{
        display: inline-block;
        width: 1.2rem;
        text-align: right;
        color: $font_color_thd;

    }
    .mt_30{
        margin-top: 0.4rem;
    }
}
.weChat_warning{
    font-size: 0.4rem;
	text-align: center;
}
</style>